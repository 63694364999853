//import { useState } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "./Dropdown";
import { useFetchProfilesQuery } from "../store";
import useNavigation from '../hooks/use-navigation';

function AccountDropdown({ setAccountId, updateCampaign, updatePortfolio }) {
    const accountName = useSelector(state => state.accounts.accountName);
    const { navigate, currentPath } = useNavigation();
    //const [selectedItem, setSelectedItem] = useState(accountName);
    const { data, error, isFetching } = useFetchProfilesQuery();
    const [search, setSearch] = useState(window.location.search);
    let searchAccountId

    const onChange = (item, id) => {
        const currentAccount = data.find(profile => {
            return profile.accountId === id;
        });

        navigate(currentPath.split("?")[0] + "?accountid=" + id)
        setSearch(null)

        setAccountId({ id, accountName: item, acos: currentAccount.targetACOS, avgPrice: currentAccount.avgPrice, maxBid: currentAccount.maxBid || 0 });
        updateCampaign({ campaignId: null, campaignName: null })
        updatePortfolio({ portfolioId: null, portfolioName: null })
    };

    let contentDropdown;
    if (isFetching) {
        contentDropdown = <Dropdown options={[]}
            //onChange={(newItem) => setSelectedItem(newItem)}
            value={accountName} defaultText="Loading..."
            className='w-52' />
    } else if (error) {
        console.log(error);
        let defaultText = "Error!"
        if (error.data.error) {
            defaultText = error.data.error
        }
        contentDropdown = <Dropdown options={[]}
            //onChange={(newItem) => setSelectedItem(newItem)}
            value={null} defaultText={defaultText}
            className='w-52' />
    } else if (data) {
        const profileOptions = data.map(profile => {
            return {
                label: profile.accountName,
                value: profile.accountId
            }
        });

        let sortedProfileOptions = [...profileOptions].sort((a, b) => {
            const valueA = a.label;
            const valueB = b.label;
            return valueA.localeCompare(valueB);
        });

        contentDropdown = <Dropdown options={sortedProfileOptions}
            onChange={onChange}
            value={accountName} defaultText="Select Account"
            className='w-44 z-40' />

        if (search && search.includes("accountid=")) {
            //console.log(search.split("accountid=")[1])
            searchAccountId = search.split("accountid=")[1].split("&")[0]
            //console.log(searchAccountId)
            const currentAccount = data.find(profile => {
                return profile.accountId === Number(searchAccountId);
            });
            //console.log(currentAccount)
            if (currentAccount) setAccountId({ id: Number(searchAccountId), accountName: currentAccount.accountName, acos: currentAccount.targetACOS, avgPrice: currentAccount.avgPrice, maxBid: currentAccount.maxBid || 0 })
        }
    }

    return (
        <div>
            {contentDropdown}
        </div>
    );
}

export default AccountDropdown;