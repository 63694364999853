import SalesTrafficTable from "./SalesTrafficTable"

function SalesTrafficList({ accountId, isWeekly }) {
    let dateArray = [], dateStrArray = [], dateStartArray = [], dateEndArray = [];

    if (isWeekly) {
        const weeksToDisplay = 26
        const dateNow = new Date();
        //-7 saat PST ile eşitlemek için
        const offset = new Date().getTimezoneOffset() - (7 * 60)
        const offsetLocal = new Date().getTimezoneOffset()
        const daysSinceLastWeek = new Date(dateNow.getTime() + (offset * 60 * 1000)).getDay();
        //console.log(daysSinceLastWeek)
        for (let i = 0; i < weeksToDisplay; i++) {
            let start = new Date();
            let end = new Date();

            if (daysSinceLastWeek > 2) {
                if (i === 0) {
                    //console.log("offset ", offset)
                    start.setDate(dateNow.getDate() - (daysSinceLastWeek));
                    end.setDate(dateNow.getDate() - (2));
                    start = new Date(start.getTime() + (offset * 60 * 1000))
                    end = new Date(end.getTime() + (offset * 60 * 1000))
                    dateStartArray[i] = (new Date(start.getTime() - (offsetLocal * 60 * 1000))).toISOString().split('T')[0];
                    dateEndArray[i] = (new Date(end.getTime() - (offsetLocal * 60 * 1000))).toISOString().split('T')[0];
                    dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
                    //console.log(dateArray[i], ", Start: ", start, ", End: ", end)
                    dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " " + start.getDate()
                        + " - "
                        + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
                } else {
                    //console.log("offset ", offset)
                    start.setDate(dateNow.getDate() - (daysSinceLastWeek + 7 + 7 * (i - 1)));
                    end.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + 7 * (i - 1)));
                    start = new Date(start.getTime() + (offset * 60 * 1000))
                    end = new Date(end.getTime() + (offset * 60 * 1000))
                    dateStartArray[i] = (new Date(start.getTime() - (offsetLocal * 60 * 1000))).toISOString().split('T')[0];
                    dateEndArray[i] = (new Date(end.getTime() - (offsetLocal * 60 * 1000))).toISOString().split('T')[0];
                    dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
                    //console.log(dateArray[i], ", Start: ", start, ", End: ", end)
                    dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " " + start.getDate()
                        + " - "
                        + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
                }

            } else {
                //console.log("offset ", offset)
                start.setDate(dateNow.getDate() - (daysSinceLastWeek + 7 + 7 * (i)));
                end.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + 7 * (i)));
                start = new Date(start.getTime() + (offset * 60 * 1000))
                end = new Date(end.getTime() + (offset * 60 * 1000))
                dateStartArray[i] = (new Date(start.getTime() - (offsetLocal * 60 * 1000))).toISOString().split('T')[0];
                dateEndArray[i] = (new Date(end.getTime() - (offsetLocal * 60 * 1000))).toISOString().split('T')[0];
                dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
                //console.log(dateArray[i], ", Start: ", start, ", End: ", end)
                dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " " + start.getDate()
                    + " - "
                    + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
            }
        }
    } else {
        const monthsToDisplay = 24
        const dateNow = new Date();
        const monthNow = dateNow.getMonth()
        const dayNow = dateNow.getDate()
        const yearNow = dateNow.getFullYear()
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let lastDayofMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if ((yearNow % 4 === 0) || ((yearNow % 4 === 1) && (monthNow === 1))) {
            lastDayofMonth[1] = 29
        }
        let monthLength = []

        let lastYearIndex
        console.log("Day: ", dayNow, " ,Month: ", monthNow, " ", months[monthNow], " ,Year: ", yearNow)
        for (let i = 0; i < monthsToDisplay; i++) {
            let start = new Date();
            let end = new Date();
            const month = ((monthNow - i) % 12 + 12) % 12

            //SET FULL YEAR --- DESIGN
            if (lastYearIndex) {
                start.setFullYear(yearNow - ((((i + 1) - lastYearIndex) / 12)))
                end.setFullYear(yearNow - ((((i + 1) - lastYearIndex) / 12)))
            }
            if (i > monthNow && !lastYearIndex) {
                lastYearIndex = i
                start.setFullYear(yearNow - 1)
                end.setFullYear(yearNow - 1)
            }

            start.setDate(1);
            end.setDate(1);
            start.setMonth(month)
            end.setMonth(month)
            if (i === 0) {
                console.log("end date: ", dayNow)
                if (dayNow > 2)
                    end.setDate(dayNow - 2);
            }
            else {
                end.setDate(lastDayofMonth[month]);
            }
            start = new Date(start.getTime())
            end = new Date(end.getTime())
            monthLength[i] = end.getDate()
            dateStartArray[i] = start.toISOString().split('T')[0];
            dateEndArray[i] = end.toISOString().split('T')[0];
            dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
            console.log(dateArray[i], ", Start: ", start, ", End: ", end)
            dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " "
                + start.getDate()
                + " - "
                + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
        }
    }

    let columnCount = dateArray.length
    let tableDiv = []

    console.log(dateArray)

    for (let i = 0; i < columnCount; i++) {
        tableDiv.push(<div className="mx-3" key={i}>
            <SalesTrafficTable date={dateArray[i]} dateStr={dateStrArray[i]} accountId={accountId}></SalesTrafficTable>
        </div>)
    }

    return <div className="flex flex-row p-3 mx-3">{tableDiv}</div>
}

export default SalesTrafficList