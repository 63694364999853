//import { useState } from "react"
import ToggleSwitch from "./PerformanceToggle"

export default function Filter({ isWeekly, setIsWeekly, view, setView }) {

    let classNameView = "flex mx-1 p-1 px-2 rounded-xl border shadow hover:cursor-pointer"
    let classNameViewSelected = "flex mx-1 p-1 px-2 rounded-xl border shadow hover:cursor-pointer bg-green-600 text-white"

    const onClickView = (v) => {
        setView(v)
    }

    return <div className="flex flex-row items-center">
        <div className="px-3">Monthly</div>
        <ToggleSwitch isWeekly={isWeekly} setIsWeekly={setIsWeekly}></ToggleSwitch>
        <div className="px-3">Weekly {isWeekly}</div>
        <div className="flex flex-row">
            <div onClick={() => { onClickView("sum") }} className={view === "sum" ? classNameViewSelected : classNameView}>
                <div>Summary</div>
            </div>
            <div onClick={() => { onClickView("std") }} className={view === "std" ? classNameViewSelected : classNameView}>
                <div>Standart</div>
            </div>
            <div onClick={() => { onClickView("dtl") }} className={view === "dtl" ? classNameViewSelected : classNameView}>
                <div>Detailed</div>
            </div>
        </div>
    </div>
}