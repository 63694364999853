import { useState } from "react"
import { useSelector } from "react-redux"
import Notes from "../../Targetings/Notes/Notes"
import Modal from "../../Modal"
export default function CampaignDiv({ campaign }) {
    const [showNotesModal, setShowNotesModal] = useState(false)
    const lastUpdated = campaign.lastUpdated
    const budgetUpdated = campaign.budgetUpdated
    const accountId = useSelector(state => state.accounts.accountId);
    const campaignId = campaign.campaignId
    const campaignType = campaign.campaignType
    const notes = campaign.notes
    let notesButtonClass = "border rounded-xl shadow px-2 p-1 mx-1 text-gray-500"
    if (notes && notes.length > 0) {
        notesButtonClass = "border rounded-xl shadow px-2 p-1 mx-1 bg-sky-100"
    }
    const state = campaign.campaignStatus || campaign.state
    const stateDisplay = state === "PAUSED" || state === "paused" || state === "ARCHIVED" || state === "archived"
    const onNotesClick = () => {
        setShowNotesModal(true)
    }

    function onNotesModalChange() {
        setShowNotesModal(false);
    };
    let typeClassName = "flex items-center ml-2 text-white text-3xs text-center px-1 rounded-full"
    let url = "/targetingx"
    if (campaignType === "SP") {
        typeClassName = typeClassName + " bg-yellow-500"
        url = "/targetingx"
    } else if (campaignType === "SB") {
        typeClassName = typeClassName + " bg-blue-500"
        url = "/targetingsb"
    } else if (campaignType === "SD") {
        typeClassName = typeClassName + " bg-green-500"
        url = "/targetingsd"
    }
    return (
        <div className="flex flex-col justify-center pl-1 break-words">
            <div className="flex border p-2 rounded-xl shadow bg-white">
                <a href={url + "?accountid=" + accountId + "&campaignid=" + campaign.campaignId} rel='noreferrer' target="_blank" className='hover:text-sky-600'>
                    {campaign.campaignName}
                </a>
                {campaignType && <div className={typeClassName}>{campaignType}</div>}
            </div>
            {stateDisplay && <div className="text-red-600">{state.toUpperCase()}</div>}
            <div className="flex flex-row my-2">
                <div>
                    <div className="text-gray-300 text-4xs">{"Campaign ID"}</div>
                    <div className="text-gray-400 text-xxs font-bold">{campaign.campaignId}</div>
                    <div className="text-gray-300 text-4xs">{"Portfolio ID"}</div>
                    <div className="text-gray-400 text-xxs font-bold">{campaign.portfolioId}</div>
                </div>
                <div className="mx-2">
                    <div className="text-gray-500 text-4xs">{"Last Updated"}</div>
                    <div className="text-gray-900 text-xxs font-bold">{lastUpdated ? lastUpdated.date : "Never"}</div>
                    <div className="text-gray-500 text-4xs">{"Budget Updated"}</div>
                    <div className="text-gray-900 text-xxs font-bold">{budgetUpdated ? budgetUpdated.date + " ----- $" + budgetUpdated.oldBudget + " >>> $" + budgetUpdated.newBudget : "Never"}</div>
                </div>
                <div className="mx-2">
                    {showNotesModal && <Modal onChange={onNotesModalChange}>
                        <Notes campaignId={campaignId} accountId={accountId}></Notes>
                    </Modal>}
                    <button onClick={onNotesClick} className={notesButtonClass}>Notes</button>
                    {(notes && notes.length > 0) && <div className="text-3xs text-gray-500">{notes.length}</div>}
                </div>
            </div>
        </div>
    )
}