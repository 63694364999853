import { useFetchListingInfoQuery } from "../../../store"
export default function AdDiv({ accountId, asin, sku }) {
    const { data } = useFetchListingInfoQuery({ accountId, asin })

    let adDiv = <div>No Data</div>
    if (data) {
        adDiv = <div className="flex flex-row shadow rounded-xl p-2 w-full bg-sky-50 items-center">
            <a href={"https://www.amazon.com/dp/" + asin}>
                <div className="w-16 h-12 rounded-md overflow-hidden">
                    <img src={data.main_image} alt="main_image" />
                </div>
            </a>
            <a href={"https://www.amazon.com/dp/" + asin} className="h-fit text-blue-800 mx-3 w-20">
                <div className="">{asin}</div>
            </a>
            <div className="w-36 words-break">{sku}</div>
            <div className="mx-3">{data.item_name}</div>
        </div>
    }

    return adDiv
}