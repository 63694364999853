import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GoSync } from "react-icons/go";
import { MdBackup } from "react-icons/md";
import { ImCancelCircle, ImCheckmark } from "react-icons/im";
import { FcApproval } from "react-icons/fc";
import { useUpdateBidMutation, updatePataraBids, useFetchCampaignLastUpdatedQuery, useFetchCampaignsQuery } from "../../store";
import Dropdown from '../Dropdown';
import Modal from '../Modal'
import Notes from './Notes/Notes'
import ProductAds from "./ProductAds/ProductAds";

function TargetingTableBulkUpdate({ tableData, selectedFlatRows, setSelectedFlatRows, campaignId, type, avgPriceCmp, length }) {
    const dispatch = useDispatch()
    const [updateBidContentVisibility, setUpdateBidContentVisibility] = useState('main-button')
    const [selectedAction, setSelectedAction] = useState('Set Bid to ($)')
    const [submittable, setSubmittable] = useState(false)
    const [formValue, setFormValue] = useState('')
    const [showNotesModal, setShowNotesModal] = useState(false)
    const [showAdsModal, setShowAdsModal] = useState(false)
    const pataraBids = useSelector(state => state.patarabids.pataraBids)
    const accountId = useSelector(state => state.accounts.accountId)
    const campaignName = useSelector(state => state.accounts.campaignName)
    const [updateBid, response] = useUpdateBidMutation()
    const { data, refetch } = useFetchCampaignLastUpdatedQuery({ id: campaignId, type })
    const { data: dataCmpList } = useFetchCampaignsQuery(accountId)
    const [selected, setSelected] = useState(false)

    const onSelectClick = () => {
        if (selected) {
            setSelectedFlatRows([])
            setSelected(false)
        } else {
            setSelectedFlatRows(tableData)
            setSelected(true)
        }
    }

    let lastUpdatedDiv = <div></div>

    // response && response.data && response.data.error ise console log

    if (data && data.date) {
        lastUpdatedDiv = (
            <div className="shadow rounded-xl px-2 py-1 text-xs">
                <div className="text-xxs text-gray-500">Last Updated</div>
                <div className="font-bold">{data.date}</div>
                <div className="text-3xs">Count: {data.count}</div>
            </div>
        )
    }

    let placementDiv = <div>Placement Info</div>

    let avgPriceDiv = <div className="ml-5">
        <div className="text-xxs text-gray-500">Avg Price</div>
        <div className="font-bold">Not Available</div>
    </div>
    if (avgPriceCmp) avgPriceDiv = <div className="ml-5">
        <div className="text-xxs text-gray-500">Avg Price</div>
        <div className="font-bold">${(avgPriceCmp).toFixed(2)}</div>
    </div>

    if (dataCmpList) {
        let camp = dataCmpList.campaigns.find(campaign => campaign.campaignId === campaignId.toString())
        //console.log(camp.dynamicBidding)
        //DYNAMIC BIDDING BILGILERINI BURADAN EKLEYECEĞIZ
        let bidTos = 0
        let bidPdp = 0
        let bidRest = 0
        if (camp) {
            const placementCount = camp.dynamicBidding.placementBidding.length

            for (let i = 0; i < placementCount; i++) {
                if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_TOP') {
                    bidTos = camp.dynamicBidding.placementBidding[i].percentage
                } else if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_PRODUCT_PAGE') {
                    bidPdp = camp.dynamicBidding.placementBidding[i].percentage
                } else if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_REST_OF_SEARCH') {
                    bidRest = camp.dynamicBidding.placementBidding[i].percentage
                }
            }
        }
        placementDiv = <div className="flex flex-row text-xxs px-3 py-1 rounded-md mx-3 shadow">
            <div className="flex flex-col px-1">
                <div>{"Top of Search"}</div>
                <div>{"Product Pages"}</div>
                <div>{"Rest of Search"}</div>
            </div>
            <div className="flex flex-col px-1 text-end font-bold">
                <div>{bidTos}%</div>
                <div>{bidPdp}%</div>
                <div>{bidRest}%</div>
            </div>
        </div>
    }

    const classname = 'w-14 mr-1 border-r border-t border-b rounded-r-lg px-2 focus:outline-0';
    const [inputClass, setInputClass] = useState(classname);

    const selectedRows = (selectedFlatRows && selectedFlatRows.map(row => row.keywordId)) || [];

    //FORM KIRMIZI İKEN SELECT ALL DİYEMESİN YA DA
    //SUBMIT ALL DERSE HATA VERSIN
    //BU ÇOK ÖNEMLİİİİİİİ

    const selectedKeywords = selectedRows.map(keyword => {
        return pataraBids.find(targeting => {
            return keyword === targeting.id
        })
    });

    const onUpdateBidClick = () => {
        setUpdateBidContentVisibility('dropdown')
    };

    const onNotesClick = () => {
        setShowNotesModal(true)
    }

    const onAdsClick = () => {
        setShowAdsModal(true)
    }

    function onNotesModalChange() {
        setShowNotesModal(false);
    };

    function onAdsModalChange() {
        setShowAdsModal(false);
    };

    const onSubmitAllClick = async () => {
        //send keywords array to the server
        //remove pause in production
        const finalKeywords = selectedKeywords.map(keyword => {
            return {
                ...keyword,
                updateType: "b"
            }
        })
        await updateBid({ selectedKeywords: finalKeywords, accountId, campaignId });
        const updatedKeywords = selectedKeywords.map(keyword => {
            return {
                ...keyword,
                isSubmitted: true
            }
        })
        dispatch(updatePataraBids(updatedKeywords));
        refetch();
        //if (response.isSuccess) { deselectAll() }
    };

    const onApproveClick = () => {
        if (submittable) {
            let updatedKeywords = [];
            if (selectedAction === 'Set Bid to ($)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    return {
                        ...keyword,
                        bid: formValue
                    }
                });
            } else if (selectedAction === 'Increase Bid by (%)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    return {
                        ...keyword,
                        bid: (keyword.bid * (1 + (formValue / 100))).toFixed(2)
                    }
                });
            } else if (selectedAction === 'Decrease Bid by (%)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    return {
                        ...keyword,
                        bid: (keyword.bid * (1 - (formValue / 100))).toFixed(2)
                    }
                });
            } else if (selectedAction === 'Increase Bid by ($)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    return {
                        ...keyword,
                        bid: (Number(keyword.bid) + Number(formValue)).toFixed(2)
                    }
                });
            } else if (selectedAction === 'Decrease Bid by ($)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    if (Number(keyword.bid) < Number(formValue)) {
                        return {
                            ...keyword,
                            bid: 0
                        }
                    } else {
                        return {
                            ...keyword,
                            bid: (Number(keyword.bid) - Number(formValue)).toFixed(2)
                        }
                    }
                });
            }
            dispatch(updatePataraBids(updatedKeywords));
            setInputClass(classname);
            setUpdateBidContentVisibility('main-button');
            setFormValue('');
            setSubmittable(false);
        } else {
            setInputClass((classname + ' border-red-500'));
        }
    };

    const onCancelClick = () => {
        setInputClass(classname);
        setUpdateBidContentVisibility('main-button');
        setFormValue('');
    };



    const dropdownOptions = [
        {
            label: 'Set Bid to ($)',
            value: 'setbid',
        },
        {
            label: 'Increase Bid by (%)',
            value: 'increase-perc',
        },
        {
            label: 'Decrease Bid by (%)',
            value: 'decrease-perc',
        },
        {
            label: 'Increase Bid by ($)',
            value: 'increase-amount',
        },
        {
            label: 'Decrease Bid by ($)',
            value: 'decrease-amount',
        },
    ];

    const onChange = (event) => {
        const value = event.target.value;
        if (!value || value === '0' || value.slice(value.length - 1) === '.' || value < 0.1) {
            //setDisabled(true);
            setInputClass((classname + ' border-red-500'));
            setSubmittable(false);
            //make input border red
        } else if (!isNaN(value) && value.length < 6) {
            //setDisabled(false);
            setInputClass((classname + ' border-green-600'));
            setSubmittable(true);
            //make input border gray again
        }
        if (!isNaN(value) && value.length < 6) {
            setFormValue(value);
        }
    };

    return (
        <div className='flex flex-row items-center p-2 h-16 justify-between'>
            {showNotesModal && <Modal onChange={onNotesModalChange}>
                <Notes campaignId={campaignId} accountId={accountId}></Notes>
            </Modal>}
            {showAdsModal && <Modal onChange={onAdsModalChange}>
                <ProductAds campaignId={campaignId} accountId={accountId} campaignName={campaignName}></ProductAds>
            </Modal>}
            <div className="flex flex-row items-center">
                <div className="flex flex-row border rounded-xl px-2">
                    <input id="check" type="checkbox" onChange={onSelectClick} ></input>
                    <div className="ml-2">
                        <div className='mx-1 text-xxs' >Selected Rows</div>
                        <div className='mx-1 font-bold' >{selectedFlatRows.length}/{length}</div>
                    </div>
                </div>
                <button className={selectedFlatRows.length === 0 ? 'p-2 m-2 bg-gray-100 text-gray-400 rounded-lg' : 'p-2 m-2 bg-sky-500 text-white rounded-lg'}
                    disabled={selectedFlatRows.length === 0 || response.isLoading} onClick={onSubmitAllClick} >
                    <div className="flex flex-row items-center">
                        Submit All
                        <div className="ml-1">
                            {response.isLoading ? <GoSync className="animate-spin" /> : (response.isSuccess ? <FcApproval /> : <MdBackup />)}
                        </div>
                    </div>
                </button>
                <button className={selectedFlatRows.length === 0 ? 'p-2 m-2 bg-gray-100 text-gray-400 rounded-lg' : 'p-2 m-2 bg-orange-400 text-white rounded-lg'}
                    disabled={selectedFlatRows.length === 0} onClick={onUpdateBidClick} hidden={updateBidContentVisibility !== 'main-button'} >
                    Update Bid
                </button>
                <div className="flex flex-row"
                    style={updateBidContentVisibility !== 'dropdown' ? { display: 'none' } : {}}>
                    <Dropdown
                        options={dropdownOptions}
                        value={selectedAction}
                        onChange={(label) => setSelectedAction(label)}
                        className='mx-1 w-48'
                        padding='py-2' />
                    <div className="flex flex-row drop-shadow">
                        <div className="flex justify-center bg-gray-50 border-l border-t border-b rounded-l-lg px-1 items-center w-6">
                            {selectedAction === 'Set Bid to ($)' || selectedAction === 'Decrease Bid by ($)' || selectedAction === 'Increase Bid by ($)'
                                ? '$'
                                : '%'}
                        </div>
                        <input
                            className={inputClass}
                            value={formValue}
                            onChange={onChange} >
                        </input>
                    </div>
                    <button
                        onClick={onApproveClick}
                        className='mx-1'>
                        <ImCheckmark />
                    </button>
                    <button
                        onClick={onCancelClick}
                        className='mx-1'>
                        <ImCancelCircle />
                    </button>
                </div>
                <div className="mx-7">
                    <div>
                        {response.data
                            ? response.data.error.length > 0
                                ? <div className="font-bold text-red-600">{"Error: " + response.data.error.length}</div>
                                : <div className="text-green-500">Error: 0</div>
                            : <div className="text-gray-300">Error: -</div>
                        }
                    </div>
                    <div>
                        {response.data
                            ? response.data.success.length > 0
                                ? <div className="text-green-500">{"Success: " + response.data.success.length}</div>
                                : <div className="font-bold text-red-600">Success: 0</div>
                            : <div className="text-gray-300">Success: -</div>
                        }
                    </div>
                </div>
                <div>{lastUpdatedDiv}</div>
                <div>{placementDiv}</div>
            </div>

            <div className="flex flex-row items-center">
                <div>{avgPriceDiv}</div>
                <div className="ml-3">
                    <button onClick={onAdsClick} className="border rounded-lg shadow p-2 text-white bg-sky-700">Product Ads</button>
                </div>
                <div className="mx-3">
                    <button onClick={onNotesClick} className="border rounded-lg shadow p-2 text-white bg-sky-700">Notes</button>
                </div>
                <div className="flex flex-row bg-yellow-50 p-2 rounded-xl">
                    <div className="mx-2 p-1 px-2 shadow rounded-xl">
                        <div className="text-3xs text-gray-500">Campaign Name</div>
                        <div className="flex text-xs select-all">{campaignName}</div>
                    </div>
                    <div className="mx-2 p-1 px-2 shadow rounded-xl">
                        <div className="text-3xs text-gray-500">Campaign Id</div>
                        <div className="flex text-xs select-all">{campaignId}</div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default TargetingTableBulkUpdate;