import useNavigation from "../hooks/use-navigation";

function Route({ path, children }) {
    const { currentPath } = useNavigation();

    if (path === currentPath.split("?")[0]) {
        return children;
    }

    return null;
};

export default Route;