import Skeleton from '../Skeleton';
import SalesTrafficReport from "./SalesTrafficReport";
import SalesTrafficCampaignReport from "./SalesTrafficCampaignReport"

function SalesTrafficTable({ date, dateStr, accountId }) {
    const { filteredPerformance, missingInfo } = SalesTrafficReport({ date, accountId })
    const campaignPerformance = SalesTrafficCampaignReport({ date, accountId })
    let tableContent
    const startDate = new Date(date.substring(0, 10))
    const endDate = new Date(date.substring(11, 21))
    const noOfDays = (endDate - startDate) / (24 * 60 * 60 * 1000) + 1
    if (filteredPerformance === "fetching") tableContent = <div className="shadow p-2 m-3 rounded-xl"><Skeleton times={12} className="h-10 w-full" /></div>
    if (filteredPerformance && missingInfo && filteredPerformance !== "fetching") {
        let missingInfoDiv
        if (missingInfo.length > 0) {
            missingInfoDiv = missingInfo.map((day, index) => {
                return <div className='ml-1 overflow-scroll' key={index}>{day}</div>
            })
        }
        tableContent =
            <div>
                <div className='flex flex-row justify-center items-center'>
                    <div className="text-4xs text-gray-400">ALL LISTINGS</div>
                    <div className='mx-2 text-4xs text-gray-400'>-</div>
                    <div className='text-4xs text-gray-400'>{date}</div>
                </div>
                <div className="flex justify-center items-end rounded-lg py-1 px-2">
                    <div className="text-gray-400 pr-2">{date.substring(0, 4)}</div>
                    <div className="font-bold text-gray-700">{dateStr}</div>
                </div>
                {missingInfoDiv && <div className='flex items-center text-4xs text-red-600'>Missing Info!!!{missingInfoDiv}</div>}
                <div className="flex flex-col items-center rounded-lg shadow p-2 bg-sky-50">
                    <div className='text-gray-500'>Total Sales</div>
                    <div className="text-base font-bold">${Number(filteredPerformance.orderedProductSales.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    <div className="text-xxs mt-1 text-gray-400">${(Number(filteredPerformance.orderedProductSales.toFixed(2)) / noOfDays).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}/day</div>
                </div>
                <div className='shadow rounded-lg my-2 px-2 bg-sky-50'>
                    <div className="grid grid-flow-col justify-around rounded-lg text-center">
                        <div className="my-2">
                            <div className="text-xs text-gray-500">TACOS</div>
                            {(campaignPerformance && campaignPerformance !== "fetching") ? <div className='font-bold'>%{(campaignPerformance.spend / filteredPerformance.orderedProductSales * 100).toFixed(2)}</div> : <div><Skeleton times={1} className="h-4 w-full" ></Skeleton></div>}
                        </div>
                        <div className="my-2">
                            <div className="text-xs text-gray-500">ACOS</div>
                            {(campaignPerformance && campaignPerformance !== "fetching") ? <div className='font-bold'>%{(campaignPerformance.spend / campaignPerformance.sales * 100).toFixed(2)}</div> : <div><Skeleton times={1} className="h-4 w-full" ></Skeleton></div>}
                        </div>
                    </div>
                    <div className="grid grid-flow-col justify-around rounded-lg text-center">
                        <div className="my-2">
                            <div className="text-xs text-gray-500">Ad Sales</div>
                            {(campaignPerformance && campaignPerformance !== "fetching") ? <div className='font-bold'>${Number(campaignPerformance.sales.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div> : <div className='flex flex-row'><Skeleton times={1} className="h-4 w-full" ></Skeleton></div>}
                        </div>
                        <div className="my-2">
                            <div className="text-xs text-gray-500">Ad Spend</div>
                            {(campaignPerformance && campaignPerformance !== "fetching") ? <div className='font-bold'>${Number(campaignPerformance.spend.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div> : <div><Skeleton times={1} className="h-4 w-full" ></Skeleton></div>}
                        </div>
                    </div>
                </div>

                <div className="grid grid-flow-col justify-stretch my-2 shadow rounded-lg p-2">
                    <div className=''>
                        <div className="my-1">
                            <div className="text-xxs">Orders</div>
                            <div className='font-bold'>{filteredPerformance.totalOrderItems.toFixed(0)}</div>
                        </div>
                        <div className="my-2">
                            <div className="text-xxs">Units</div>
                            <div className='font-bold'>{filteredPerformance.unitsOrdered.toFixed(0)}</div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="my-1">
                            <div className="text-xxs">Sessions</div>
                            <div className='font-bold'>{filteredPerformance.sessions.toFixed(0)}</div>
                        </div>
                        <div className="flex flex-col my-1">
                            <div>
                                <div className="text-4xs text-gray-500">Mobile</div>
                                <div className="text-xxs text-gray-500 font-bold">{filteredPerformance.mobileAppSessions.toFixed(0)}</div>
                            </div>
                            <div>
                                <div className="text-4xs text-gray-500">Browser</div>
                                <div className="text-xxs text-gray-500 font-bold">{filteredPerformance.browserSessions.toFixed(0)}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="my-1">
                            <div className="text-xxs">Page Views</div>
                            <div className='font-bold'>{filteredPerformance.pageViews.toFixed(0)}</div>
                        </div>
                        <div className="flex flex-col my-1">
                            <div>
                                <div className="text-4xs text-gray-500">Mobile</div>
                                <div className="text-xxs text-gray-500 font-bold">{filteredPerformance.mobileAppPageViews.toFixed(0)}</div>
                            </div>

                            <div>
                                <div className="text-4xs text-gray-500">Browser</div>
                                <div className="text-xxs text-gray-500 font-bold">{filteredPerformance.browserPageViews.toFixed(0)}</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="grid grid-flow-col justify-stretch my-2 shadow rounded-lg p-2">
                    <div className=''>
                        <div className="my-1">
                            <div className="text-xs">CVR</div>
                            <div className='font-bold'>%{((filteredPerformance.totalOrderItems / filteredPerformance.sessions) * 100).toFixed(2)}</div>
                        </div>
                        <div className="my-1">
                            <div className="text-xxs text-gray-400">CVR B2B</div>
                            <div className='text-xs font-bold text-gray-400'>%{((filteredPerformance.totalOrderItemsB2B / filteredPerformance.sessionsB2B) * 100).toFixed(2)}</div>
                        </div>
                    </div>
                    <div>
                        <div className="my-1">
                            <div className="text-xxs">Avg.Price</div>
                            <div className='font-bold'>${(filteredPerformance.orderedProductSales / filteredPerformance.unitsOrdered).toFixed(2)}</div>
                        </div>
                        <div className="my-1">
                            <div className="text-4xs text-gray-500">Avg.Sale/Order</div>
                            <div className="text-xxs text-gray-500 font-bold">${(filteredPerformance.orderedProductSales / filteredPerformance.totalOrderItems).toFixed(2)}</div>
                        </div>
                    </div>
                    <div>
                        <div className="my-1">
                            <div className="text-xxs">Refund Rate</div>
                            <div className='font-bold'>%{((filteredPerformance.unitsRefunded / filteredPerformance.unitsOrdered) * 100).toFixed(2)}</div>
                        </div>
                        <div className="my-1">
                            <div className="text-3xs text-gray-500">Units Refunded</div>
                            <div className='text-xxs text-gray-500 font-bold'>{filteredPerformance.unitsRefunded.toFixed(0)}</div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-flow-col justify-stretch my-2 shadow rounded-lg p-2">
                    <div className=''>
                        <div className="my-2">
                            <div className="text-xxs">B2B Sales</div>
                            <div className='font-bold'>${Number(filteredPerformance.orderedProductSalesB2B.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                            <div className='text-xs text-gray-400'>%{(Number(filteredPerformance.orderedProductSalesB2B.toFixed(2)) / Number(filteredPerformance.orderedProductSales.toFixed(2)) * 100).toFixed(2)}</div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="my-2">
                            <div className="text-xxs">B2B Orders</div>
                            <div className='flex items-center'>
                                <div className='font-bold mr-1'>{filteredPerformance.totalOrderItemsB2B.toFixed(0)}</div>
                                <div className='text-xxs text-gray-400'>%{((filteredPerformance.totalOrderItemsB2B / filteredPerformance.totalOrderItems) * 100).toFixed(2)}</div>
                            </div>
                        </div>
                        <div className="my-2">
                            <div className="text-xxs">B2B Units</div>
                            <div className='flex items-center'>
                                <div className='font-bold mr-1'>{filteredPerformance.unitsOrderedB2B.toFixed(0)}</div>
                                <div className='text-xxs text-gray-400'>%{((filteredPerformance.unitsOrderedB2B / filteredPerformance.unitsOrdered) * 100).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className="my-2">
                            <div className="text-xxs">B2B Sessions</div>
                            <div className='flex items-center'>
                                <div className='font-bold mr-1'>{filteredPerformance.sessionsB2B.toFixed(0)}</div>
                                <div className='text-xxs text-gray-400'>%{((filteredPerformance.sessionsB2B / filteredPerformance.sessions) * 100).toFixed(2)}</div>
                            </div>
                        </div>
                        <div className="my-2">
                            <div className="text-xxs">B2B Page Views</div>
                            <div className='flex items-center'>
                                <div className='font-bold mr-1'>{filteredPerformance.pageViewsB2B.toFixed(0)}</div>
                                <div className='text-xxs text-gray-400'>%{((filteredPerformance.pageViewsB2B / filteredPerformance.pageViews) * 100).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }

    return <div className="w-96 p-3 border shadow rounded-xl">
        {tableContent}
    </div>
}
export default SalesTrafficTable